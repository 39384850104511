import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    styled, TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import PageTitle from "./widgets/PageTitle";
import {useNavigate} from "react-router-dom";
import {currencyCode} from "./utils/AppConstants";
import {useContext, useState} from "react";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_tCR2cZPbnSK1QPWrEMG1PYre');
const MyStyledButton = styled(Button)({
    width: 100,
    height: 100,
    borderWidth: 3,
    borderRadius: 15,
    borderColor: "#2a9bb6",
    fontSize: 24,
    fontWeight: "bold",
    color:"#000"

});


export default function Campaign({selectedCampaign}) {

    const {sessionData} = useContext(ReferenceDataContext)
    const navigate = useNavigate()
    if(selectedCampaign == null)
    {
        navigate("/")
    }


    console.log(selectedCampaign)

    const onAmountItemClick = (amount) => {
        navigate('/getuserinfo', { state: {amount:amount, typeOfDonation:selectedCampaign.title}})
    };
    const onCustomAmountClick = (amount) => {

        handleClickOpen()
    };

    const [customAmount, setCustomAmount] = useState(0);
    const onTextChangeCustomAmount = (e) => setCustomAmount(e.target.value);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleContinue = () => {
        setOpen(false);
        navigate('/getuserinfo', { state: {amount:customAmount, typeOfDonation:selectedCampaign.title}})
    };

    return (
        <div style={{
            width: "100%",
            minHeight: "90vh",
            position: "relative"
        }}>


            <div style={{width: "100%"}}>
                <PageTitle title={selectedCampaign.title}/>
                <img src={selectedCampaign.banner} style={{width: "100%"}}/>
            </div>


            <div style={{
                paddingBottom: "100px",
                height:"100%",
                paddingTop:"50px"
            }}>
                <p>

                    <Grid container justify="center" spacing={3} >

                        <Grid item xs={4} sm={4} md={4} >
                            <MyStyledButton
                                className="square-box"
                                variant={"outlined"}
                                onClick={(e) => {
                                    onAmountItemClick(selectedCampaign.amount1)
                                }}>
                                {sessionData.currencysymbol}{selectedCampaign.amount1}
                            </MyStyledButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} >
                            <MyStyledButton
                                className="square-box"
                                variant={"outlined"}
                                onClick={(e) => {
                                    onAmountItemClick(selectedCampaign.amount2)
                                }}>
                                {sessionData.currencysymbol}{selectedCampaign.amount2}
                            </MyStyledButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} >
                            <MyStyledButton
                                className="square-box"
                                variant={"outlined"}
                                onClick={(e) => {
                                    onAmountItemClick(selectedCampaign.amount3)
                                }}>
                                {sessionData.currencysymbol} {selectedCampaign.amount3}
                            </MyStyledButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <MyStyledButton
                                className="square-box"
                                variant={"outlined"}
                                onClick={(e) => {
                                    onAmountItemClick(selectedCampaign.amount4)
                                }}>
                                {sessionData.currencysymbol}{selectedCampaign.amount4}
                            </MyStyledButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} >
                            <MyStyledButton
                                className="square-box"
                                variant={"outlined"}
                                onClick={(e) => {
                                    onAmountItemClick(selectedCampaign.amount5)
                                }}>
                                {sessionData.currencysymbol}{selectedCampaign.amount5}
                            </MyStyledButton>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} key={"6"}>
                            <MyStyledButton
                                className="square-box"
                                variant={"outlined"}
                                onClick={(e) => {
                                    onCustomAmountClick()
                                }}>
                                ...
                            </MyStyledButton>
                        </Grid>

                    </Grid>
                </p>

            </div>

            <div style={{
                bottom: "0",
                height: "100px",
                left: "0",
                position: "absolute",
                right: "0"
            }}>

                    <img src={selectedCampaign.adbanner} style={{
                        width:"100%"
                    }}/>

            </div>



            <Dialog open={open} onClose={handleClose}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      Please enter amount:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onChange={onTextChangeCustomAmount}
                        value={customAmount}
                        margin="dense"
                        id="amount"
                        label="Amount"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinue}>Continue</Button>
                </DialogActions>
            </Dialog>

        </div>


    );

}