import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    styled,
    TextField
} from "@mui/material";
import sadaqaBoxImage from './image/sadqa_box_bg.png';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";


const MyStyledButton = styled(Button)({
    width: 100,
    height: 100,
    borderWidth: 3,
    borderRadius: 15,
    borderColor: "#FFFFFF",
    color: "#FFFFFF",
    fontSize: 24,
    fontWeight: "bold",

});
export default function Sadaqa({selectedCampaign,appData}) {
    const {sessionData} = useContext(ReferenceDataContext)
    const navigate = useNavigate()
    if(selectedCampaign == null)
    {
        navigate("/")
    }

    const onAmountItemClick = (amount) => {

        navigate('/getuserinfo', { state: {amount:amount, typeOfDonation:"Sadaqa"}})
    };
    const onCustomAmountClick = (amount) => {

        handleClickOpen()
    };


    const [customAmount, setCustomAmount] = useState(0);
    const onTextChangeCustomAmount = (e) => setCustomAmount(e.target.value);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleContinue = () => {
        setOpen(false);
        navigate('/getuserinfo', { state: {amount:customAmount, typeOfDonation:selectedCampaign.title}})
    };
    return (
        <>

        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{ minHeight: "800px", maxHeight:"800px" }}
        >
            <Grid

                container
                direction="column"
                alignItems="center"
                justify="center"
                minHeight={"700px"}
                maxHeight={"700px"}
                style={{
                    backgroundImage: `url(${sadaqaBoxImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",



                }}

            >

                <Grid item xs={12} sm={12} md={12}  minHeight={"150px"} maxHeight={"150px"} >
                    <Typography variant={"body1"}
                                maxWidth={300}  padding={5} style={{color:"#FFFFFF"}}>
                        {appData?.sadqaboxheadertext}
                    </Typography>

                </Grid>
                <Grid   item xs={12} sm={12} md={12}  align="center">
                    <Grid
                        container
                        item
                        alignItems="center"
                        justify="center"
                        minHeight={"400px"}
                        maxHeight={"400px"}
                        style={{
                            maxWidth: 350,
                        }}

                    >

                        <Grid container alignItems="center" spacing={1} margin={6}>

                            <Grid item xs={6} sm={6} md={6} key={selectedCampaign?.amount1}>
                                <MyStyledButton
                                    className="square-box"
                                    variant={"outlined"}
                                    onClick={(e) => {
                                        onAmountItemClick(selectedCampaign?.amount1)
                                    }}>
                                    {sessionData.currencysymbol}{selectedCampaign?.amount1}
                                </MyStyledButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} key={selectedCampaign?.amount2}>
                                <MyStyledButton
                                    className="square-box"
                                    variant={"outlined"}
                                    onClick={(e) => {
                                        onAmountItemClick(selectedCampaign?.amount2)
                                    }}>
                                    {sessionData.currencysymbol}{selectedCampaign?.amount2}
                                </MyStyledButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} key={selectedCampaign?.amount3}>
                                <MyStyledButton
                                    className="square-box"
                                    variant={"outlined"}
                                    onClick={(e) => {
                                        onAmountItemClick(selectedCampaign?.amount3)
                                    }}>
                                    {sessionData.currencysymbol}{selectedCampaign?.amount3}
                                </MyStyledButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} key={selectedCampaign?.amount4}>
                                <MyStyledButton
                                    className="square-box"
                                    variant={"outlined"}
                                    onClick={(e) => {
                                        onAmountItemClick(selectedCampaign?.amount4)
                                    }}>
                                    {sessionData.currencysymbol}{selectedCampaign?.amount4}
                                </MyStyledButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} key={selectedCampaign?.amount5}>
                                <MyStyledButton
                                    className="square-box"
                                    variant={"outlined"}
                                    onClick={(e) => {
                                        onAmountItemClick(selectedCampaign?.amount5)
                                    }}>
                                    {sessionData.currencysymbol}{selectedCampaign?.amount5}
                                </MyStyledButton>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} key={"6"}>
                                <MyStyledButton
                                    className="square-box"
                                    variant={"outlined"}
                                    onClick={(e) => {
                                        onCustomAmountClick()
                                    }}>
                                    ...
                                </MyStyledButton>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}  minHeight={"150px"} maxHeight={"150px"} >
                    <Typography variant={"body1"}
                                maxWidth={350}  padding={5} style={{color:"#FFFFFF"}}>
                        {appData?.sadqaboxfootertext}
                    </Typography>

                </Grid>
            </Grid>


        </Grid>


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter amount:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onChange={onTextChangeCustomAmount}
                        value={customAmount}
                        margin="dense"
                        id="amount"
                        label="Amount"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinue}>Continue</Button>
                </DialogActions>
            </Dialog>
            </>


    );
}