import * as React from 'react';
import Box from "@mui/material/Box";
import Image from "mui-image";
import {Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import {useEffect} from "react";

export default function ThankYou({props}) {

    const amount = Number(props.amount).toFixed(2)



    useEffect(()=>{
        setInterval(() => {
            window.location = window.location.pathname
        }, 5000)
    },[])



    return (
        <Grid container justify="center"
              alignItems="center"
              justifyContent="center">
            <Grid item alignItems="center"
                  justifyContent="center">
                <Box style={{width: "400px"}}>

                    <Box>
                        <Image src="image/logo_signin.png" style={{width: "200px", height:"180px"}}/>
                    </Box>
                    <Box>
                        <Image src="image/image_boy_signin.png" style={{width: "350px"}}/>
                    </Box>


                </Box>

                <Box style={{textAlign:"center"}}>
                    <Typography variant={"h5"}>
                        Thank you for your Donation
                    </Typography>

                </Box>
                <Box style={{textAlign:"center"}}>
                    <Typography variant={"h5"}>
                    ${amount}
                    </Typography>
                </Box>
                <Box style={{textAlign:"center"}}>
                    <Typography variant={"h5"}>
                    {props.type}
                    </Typography>
                </Box>
                <Toolbar />
                <Button style={{width:200}} variant="contained" onClick={() =>
                    window.location = window.location.pathname
                    //window.open("https://app.al-ayn.ca")
                } style={{color:"white"}}>Return to App</Button>
            </Grid>

        </Grid>

    );

}