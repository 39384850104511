import React, { createContext, useState } from "react";



export const ReferenceDataContext = createContext("default");

export const ReferenceDataContextProvider = props => {

    const appData = props.appData[0]
    const [sessionData, setSessionData] = useState({firstName:null,lastName:null,email:null, currenccode:appData?.currencycode, currencysymbol:appData?.currencysymbol});


    return (
        <ReferenceDataContext.Provider value={{sessionData:sessionData, setSessionData:setSessionData}}>
            {props.children}
        </ReferenceDataContext.Provider>
    );
};


