import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useContext, useEffect} from "react";
import {Button, ButtonGroup, createTheme, Grid, Link, MenuItem, MenuList, Paper} from "@mui/material";
import {Image} from "mui-image";
import {NavLink, Route, Routes} from "react-router-dom";


import {
    useNavigate,
} from "react-router-dom";
import Campaign from "./Campaign";
import {makeStyles} from "@mui/styles";
import Sadaqa from "./Sadaqa";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import RegisterMySadqaBox from "./RegisterMySadqaBox";
import MyDonations from "./MyDonations";
import CheckOut from "./CheckOut";
import sadaqaBoxImage from "./image/sadqa_box_bg.png";
import theme from "./utils/Theme";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";
import ThankYou from "./ThankYou";
import PrepareCheckOut from "./PrepareCheckOut";
import GetUserInfo from "./GetUserInfo";
import {Close} from "@mui/icons-material";


const drawerWidth = 340;
const menu = []


export const useStylesLandingPage = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            width: "500px",

        },
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },

    mainContentWraper: {

        [theme.breakpoints.up("md")]: {
            width: "500px",

        },
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },


    leftMenu: {
        backgroundColor: '#FFFFFF'
    },
    bottomPush: {
        clear:"both",
        textAlign: "center",
        padding: 10,


    }
}));

function LandingPage(props) {
    const navigate = useNavigate();




    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedCampaign, setSelectedCampaign] = React.useState(null);

    const classes = useStylesLandingPage();


    //prepare menu items
    let appData = props.appData[0]
    let campaignData = props.campaignData

    const {sessionData, setSessionData} = useContext(ReferenceDataContext)
    console.log(sessionData , "sessionData2")


    CreateMenuModel({appData, campaignData})


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleMenuItemClick = (id, data, path) => {
        if (mobileOpen) {
            handleDrawerToggle();
        }

        if (id === "Donation") {
            setSelectedCampaign(data)
            navigate("/campaign")
        } else if (id === "Sadaqa") {
            setSelectedCampaign(data)
            navigate("/sadaqa")
        } else if (id === "event") {
            console.log(appData?.eventlink, "event link")
            window.open(appData?.eventlink, '_blank');

        } else {
            navigate(path)
        }


    };


    const drawer = (
        <div style={{minHeight:"100vh"}}>

        <div style={{minHeight:"90vh"}}>
            <Close style={{marginLeft:300, marginTop:10}}

                   onClick={(event) =>
                       handleDrawerToggle()
                   }
            />
            <br />
            <br />
            <Grid container xs={12}>
                <Grid item  xs={6}>
                    <Image src="image/applestore.png" style={{
                        cursor: "pointer",
                        float: 'none',
                        width: 150,
                        resizeMode : 'contain',
                        marginLeft: 'auto',
                        marginRight: 'auto',


                    }}
                           onClick={() => {
                               window.open("https://apps.apple.com/us/app/al-ayn-canada-ascf/id1550986838", '_blank');
                           }}

                    />
                </Grid>
                <Grid item xs={6}>
                    <Image src="image/gstore.png" style={{
                        cursor: "pointer",
                        float: 'none',
                        width: 150,
                        resizeMode : 'contain',
                        marginLeft: 'auto',
                        marginRight: 'auto',

                    }}
                           onClick={() => {
                               window.open("https://play.google.com/store/apps/details?id=com.tis.alayn.canada&hl=en&gl=US", '_blank');
                           }}

                    />
                </Grid>
            </Grid>


            <List className={classes.leftMenu}

            >
                {
                    menu.map(({index, id, title, data, path}) => (
                        <ListItem key={title}

                                  style={{backgroundColor: "#FFFFFF", cursor: "pointer"}}
                                  component={Link}
                                  selected={props.selectedIndex === props.index}
                                  onClick={(event) =>
                                      handleMenuItemClick(id, data, path)
                                  }

                                  divider
                        >
                            <ListItemIcon>
                                <Image src="image/menu_item_dot.png" width={5} style={{marginLeft: 20}}/>
                            </ListItemIcon>
                            <ListItemText primary={title} style={{marginLeft: -10}}/>
                        </ListItem>
                    ))}
            </List>



        </div>
            <div >

                <ListItem

                          style={{cursor: "pointer", width: '340px',padding:10}}
                          component={Link}
                          onClick={(event) =>
                              window.open("http://www.ecnetsolutions.ca", '_blank')
                          }
                >
                <Image src="image/ecnet_logo.png" style={{
                    width:"220px"

                }}


                />
                </ListItem>
            </div>

        </div>
    );

    const {win} = props;
    const container = win !== undefined ? () => window().document.body : undefined;

    return (


        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                elevation={0}
                color={"transparent"}
                style={{background: "#FFFFFF"}}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}

                        style={{position: "absolute"}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Image src="image/header_logo.png" style={{
                        float: 'none',
                        width: '130px',
                        marginLeft: 'auto',
                        marginRight: 'auto',

                    }}/>
                </Toolbar>
            </AppBar>


            <CssBaseline/>
            <Paper
                sx={{width: {sm: 0}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                <Drawer
                    style={{backgroundColor: "#FFFFF"}}
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >

                    {drawer}


                </Drawer>
                {/*<Drawer*/}
                {/*    style={{backgroundColor: "#FFF"}}*/}
                {/*    variant="permanent"*/}
                {/*    sx={{*/}
                {/*        display: {xs: 'none', sm: 'block'},*/}
                {/*        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},*/}
                {/*    }}*/}
                {/*    open*/}
                {/*>*/}
                {/*    {drawer}*/}
                {/*</Drawer>*/}
            </Paper>
            <Grid
                container
                spacing={0}
                justifyContent={"center"}
                style={{minHeight: "100vh"}}
            >

                    <Box
                        className={classes.mainContentWraper}
                    >
                        <Toolbar/>
                        <Routes>
                            <Route path="/" exact element={<HomePage props={props} appData={appData} setSelectedCampaign={setSelectedCampaign}/> }/>
                            <Route path="/campaign" exact element={<Campaign selectedCampaign={selectedCampaign}/>}/>
                            <Route path="/mydonations" exact element={<MyDonations/>}/>
                            <Route path="/sadaqa" exact
                                   element={<Sadaqa selectedCampaign={selectedCampaign} appData={appData}/>}/>
                            <Route path="/aboutus" exact element={<AboutUs appData={appData}/>}/>
                            <Route path="/contactus" exact element={<ContactUs appData={appData}/>}/>
                            <Route path="/registermysadqabox" exact element={<RegisterMySadqaBox/>}/>
                            <Route path="/checkout" exact element={<PrepareCheckOut />}/>
                            <Route path="/getuserinfo" exact element={<GetUserInfo />}/>
                            <Route path="/thankyou" exact element={<ThankYou/>}/>

                        </Routes>

                    </Box>

            </Grid>
        </Box>


    );
}

LandingPage.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default LandingPage;

function HomePage({props, appData, setSelectedCampaign}) {

    const navigate = useNavigate()
    return (

        <Box>
            <Box style={{
                minHeight: "80vh",
                position: "relative"
            }}>
                <Toolbar/>
                <Box style={{
                    backgroundImage: 'url(/image/ellipse.png)',
                    backgroundSize: 'contain',
                    backgroundPosition: 'left',
                    backgroundRepeat: "no-repeat",
                    back: 50,

                }} width={360}>

                    <Grid container alignItems="center"
                          justify="center">
                        <Grid item xs={3}>
                            <Image src="/image/home_body_logo.png"

                                   style={{marginLeft: 10, width:"163px"}}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                            >
                                <Button variant="text" style={{
                                    background: 'url(/image/img_button_home_normal.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    width: 180,
                                    height: 70,
                                    marginTop: -20,
                                    marginLeft: -50,
                                    color: "#FFFFFF",
                                    fontSize:12,
                                    lineHeight: 1,
                                    fontWeight: "bold"
                                }}
                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[0])
                                            navigate("/campaign")
                                        }

                                }
                                >{props.campaignData[0].title}</Button>
                                <Button variant="text" style={{
                                    background: 'url(/image/img_button_home_normal.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: "no-repeat",
                                    width: 180,
                                    height: 70,
                                    marginLeft: 60,
                                    color: "#FFFFFF",
                                    fontSize:12,
                                    lineHeight: 1,
                                    marginTop: 10,
                                    fontWeight: "bold"
                                }}
                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[1])
                                            navigate("/campaign")
                                        }
                                        }

                                >{props.campaignData[1].title}</Button>
                                <Button variant="text" style={{
                                    background: 'url(/image/img_button_home_normal.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: "no-repeat",
                                    width: 180,
                                    height: 70,
                                    marginLeft: 100,
                                    marginTop: 10,
                                    fontSize:12,
                                    lineHeight: 1,
                                    fontWeight: "bold",
                                    color: "#FFFFFF",


                                }}
                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[2])
                                            navigate("/campaign")
                                        }
                                        }
                                >{props.campaignData[2].title}</Button>
                                <Button variant="text" style={{
                                    background: 'url(/image/img_button_home_normal.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: "no-repeat",
                                    width: 180,
                                    height: 70,
                                    marginLeft: 100,
                                    marginTop: 10,
                                    fontSize:12,
                                    lineHeight: 1,
                                    fontWeight: "bold",
                                    color: "#FFFFFF",

                                }}
                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[3])
                                            navigate("/campaign")
                                        }
                                        }
                                >{props.campaignData[3].title}</Button>

                                <Button variant="text" style={{
                                    background: 'url(/image/btn_sadqa.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: "no-repeat",
                                    width: 180,
                                    height: 70,
                                    marginLeft: 100,
                                    marginTop: 10,
                                    fontSize:12,
                                    lineHeight: 1,
                                    fontWeight: "bold",
                                    color: "#FFFFFF",

                                }}

                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[6])
                                            navigate("/sadaqa")
                                        }
                                        }
                                >{props.campaignData[6].title}</Button>
                                <Button variant="text" style={{
                                    background: 'url(/image/img_button_home_normal.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: "no-repeat",
                                    width: 180,
                                    height: 70,
                                    marginTop: 10,
                                    marginLeft: 50,
                                    fontSize:12,
                                    lineHeight: 1,
                                    fontWeight: "bold",
                                    color: "#FFFFFF",
                                }}

                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[4])
                                            navigate("/campaign")
                                        }
                                        }
                                >{props.campaignData[4].title}</Button>
                                <Button variant="text" style={{
                                    background: 'url(/image/img_button_home_normal.png) no-repeat center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: "no-repeat",
                                    width: 180,
                                    height: 70,
                                    marginTop: 10,
                                    marginLeft: -50,
                                    fontSize:12,
                                    lineHeight: 1,
                                    fontWeight: "bold",
                                    color: "#FFFFFF",
                                }}
                                        onClick={() => {
                                            setSelectedCampaign(props.campaignData[5])
                                            navigate("/campaign")
                                        }
                                        }
                                >{props.campaignData[5].title}</Button>

                            </ButtonGroup>
                        </Grid>

                    </Grid>

                </Box>

            </Box>


            <div style={{
                bottom: "0",
                left: "0",
                position: "relative",
                right: "0"
            }}>

                <img src={appData.adhomepage} style={{
                    width: "100%"
                }}/>

            </div>
        </Box>

    );
}

function CreateMenuModel({appData, campaignData}) {


    let home = {
        index: 0,
        id: "Home",
        title: appData.home,
        path: ''
    }

    let mydonations = {
        index: 1,
        id: "MyDonations",
        title: appData.mydonations,
        path: '/mydonations'
    }


    let aboutus = {
        index: 2,
        id: "AboutUs",
        title: appData.aboutalayn,
        path: '/aboutus'
    }


    let sadaqa = {
        index: 3,
        id: "Sadaqa",
        title: campaignData[6].title,
        data: campaignData[6],
        path: '/sadaqa'
    }


    let homebtn1 = {
        index: 4,
        id: "Donation",
        title: campaignData[0].title,
        data: campaignData[0],
        path: ''
    }

    let homebtn2 = {
        index: 5,
        id: "Donation",
        title: campaignData[1].title,
        data: campaignData[1],
        path: ''
    }

    let homebtn3 = {
        index: 6,
        id: "Donation",
        title: campaignData[2].title,
        data: campaignData[2],
        path: ''
    }

    let homebtn4 = {
        index: 7,
        id: "Donation",
        title: campaignData[3].title,
        data: campaignData[3],
        path: ''
    }

    let homebtn5 = {
        index: 8,
        id: "Donation",
        title: campaignData[4].title,
        data: campaignData[4],
        path: ''
    }


    let homebtn6 = {
        index: 9,
        id: "Donation",
        title: campaignData[5].title,
        data: campaignData[5],
        path: ''
    }
    let registerMysadqaBox = {
        index: 9,
        id: "registermysadqabox",
        title: appData.registermysadqabox,
        path: '/registermysadqabox'
    }
    let event = {
        index: 9,
        id: "event",
        title: appData.eventname,
        path: ''
    }
    let contactus = {
        index: 9,
        id: "contactus",
        title: appData.contactus,
        path: '/contactus'
    }
    if (menu.length === 0) {
        menu.push(home,mydonations, sadaqa, aboutus, homebtn1, homebtn2, homebtn3, homebtn4, homebtn5, homebtn6, event, contactus)
    }
}
