import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

export default function PageTitle(props)
{

    return (
        <Box display={"flex"} justifyContent="center" alignItems="center" padding={1}>

                <Typography variant={"body1"}><b>{props.title}</b></Typography>

        </Box>
    )
}