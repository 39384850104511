import * as React from 'react';


export default function AboutUs({appData})
{
    return (
        <>
            <img src={appData?.aboutusimage} style={{
                marginLeft: "auto",
                marginRight: "auto", width: "100%"
            }}/>
        </>

    );
}