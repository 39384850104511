import { Alert } from "@mui/lab";
import React, { Component } from "react";
import './App.css';
import ApiRouter from "./components/api/ApiRouter";
import ProgressLoader from "./components/widgets/ProgressLoader";
import { network_error_msg} from "./components/utils/AppConstants";

import { withRouter } from "./components/utils/WithRouter";
import LandingPage from "./components/LandingPage";
import {createTheme, ThemeProvider} from "@mui/material";
import Login from "./Login";
import {ReferenceDataContext, ReferenceDataContextProvider} from "./components/utils/ReferenceDataContext";
import {useNavigate} from "react-router-dom";
import queryString from 'query-string';
import ThankYou from "./components/ThankYou";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDK9fVDo0y35OkKTuYxgk4vZfWBphpylaw",
    authDomain: "al-ayn-canada.firebaseapp.com",
    projectId: "al-ayn-canada",
    storageBucket: "al-ayn-canada.appspot.com",
    messagingSenderId: "919035910197",
    appId: "1:919035910197:web:f067904f2499b6dfd1797c",
    measurementId: "G-DNLRKR7KRG"
};

const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#49b2cb',
            light:'#FFFFFF'

        },
        secondary: {
            // This is green.A700 as hex.
            main: '#207184',
        },
    },
});

class App extends Component {

    //static contextType = ReferenceDataContext;

    constructor() {
        super();
        this.state = {
            isDataFetched: false,
            appData: null,
            campaignData: null,
            isNetworkError: false,
            email:null,
            redirectStatus: {},
        };

        this.appRef = React.createRef();

    }

    componentDidMount() {

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app)

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const redirect_status = params.get('redirect_status');
        const amount = params.get('amount');
        const type = params.get('type');
        console.log(redirect_status,"redirect_status")
        this.setState({redirectStatus:{status: redirect_status , amount:amount, type: type}})



        params.delete("redirect_status")
        params.delete("amount")
        params.delete("type")

        //let redirect_status = query.get('redirect_status')
        // let {sessionData} = this.context
        // console.log(redirect_status,"redirect_status")

        if(redirect_status !== "succeeded")
        {
            this.fetchAppData()
        }


    }


    fetchAppData = () => {
        var method = "id=appsetting"
        ApiRouter.getData(method, (response) => {


            this.setState({ appData: response })
            this.fetchAppCampaign()


        }, (err) => {
            //error
            this.setState({ isNetworkError: true })
            this.setState({ isDataFetched: true })
        });
    }


    fetchAppCampaign = () => {
        var method = "id=getcampaign"
        ApiRouter.getData(method, (response) => {

            this.setState({ campaignData: response })
            this.setState({ isNetworkError: false })
            this.setState({ isDataFetched: true })

        }, (err) => {
            //error
            this.setState({ isNetworkError: true })
            this.setState({ isDataFetched: true })
        });
    }


     setEmail = (_email) =>{

         this.setState({email:_email})

    }

    render()
    {

        return (
            <div className="App">
                <ThemeProvider theme={theme}>

                    {this.state.redirectStatus.status === "succeeded" ? (
                            <ThankYou   props={this.state.redirectStatus}/>
                        ) :
                        (
                            <>
                    {this.state.isNetworkError ?
                        <Alert severity="error">{network_error_msg}</Alert> :
                        <div>{
                            this.state.isDataFetched && this.state.appData != null ?

                                (<>
                                <ReferenceDataContextProvider appData = {this.state.appData}>
                                            <LandingPage  appData = {this.state.appData} campaignData = {this.state.campaignData}  redirectStatus={this.state.redirectStatus}/>
                                </ReferenceDataContextProvider>
                                </>)
                                 :
                                // Loading Box
                                ( <ProgressLoader/>)
                        }
                        </div>
                    }</>)}
                </ThemeProvider>
            </div>

        );
    }
}

export default withRouter(App);

