import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#3F8AE0'
        },
        secondary: {
            main: '#326eb3'
        }
    },
});

export default theme;