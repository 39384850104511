import * as React from 'react';
import {Button, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import PageTitle from "./widgets/PageTitle";
export default function RegisterMySadqaBox()
{
    return (
        <>
            <PageTitle title="About Al-Ayn"/>
            <Box
                component="form"
                padding={3}
                noValidate
                autoComplete="off"
            >
                <TextField id="firstname" label="First Name" variant="outlined" fullWidth style={{marginTop:"3px"}}/>
                <TextField id="lastname" label="Last Name" variant="outlined" fullWidth style={{marginTop:"4px"}}/>
                <TextField id="email" label="Email" variant="outlined" fullWidth style={{marginTop:"4px"}}/>
                <TextField id="phone" label="Phone" variant="outlined" fullWidth style={{marginTop:"4px"}}/>
                <TextField id="address" label="Address" variant="outlined"  rows={4} multiline fullWidth style={{marginTop:"4px"}}/>
                <TextField id="boxnumber" label="Box Number" variant="outlined" fullWidth style={{marginTop:"4px"}}/>
                <TextField id="locknumber" label="Lock Number" variant="outlined" fullWidth style={{marginTop:"4px"}}/>
                <Button variant="contained" fullWidth style={{marginTop:"3px", color:"#FFFFFF"}}>SEND</Button>



            </Box>
        </>


    );
}