import * as React from 'react';
import PageTitle from "./widgets/PageTitle";
import Box from "@mui/material/Box";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import ApiRouter from "./api/ApiRouter";
import {ReferenceDataContext} from "./utils/ReferenceDataContext";
import { moment } from 'moment';
import Typography from "@mui/material/Typography";
import {Image} from "mui-image";
export default function MyDonations()
{

    const [myDonations, setMyDonations] = useState(null)
    const saved = localStorage.getItem("emailInput");
    const _email = JSON.parse(saved);


    useEffect(
        function effectFunction() {
            var method = "id=mydonations"
            var params = {email:_email}
            ApiRouter.fetchDataPost(method, params,(response) => {
                console.log(response,"MyDonations")
                setMyDonations(response)

            }, (err) => {
                //error
                console.log("error","MyDonations")
            });

        },
        [_email]
    )



    return (
        <>

            <PageTitle title="My Donations"/>

            {myDonations !== null ? (

                     myDonations?.map((row, index) => (


                                <Grid container spacing={1} key={row.creacteddate} alignItems={"start"} padding={2}>

                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterBottom variant="subtitle1" component="div" textAlign={"left"}>
                                                    <b>{row.currencycode}  {row.amount}</b> ({row.type})
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                    {row.createddate}

                                                </Typography>
                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" component="div">
                                                {row.paymenttype === "GooglePay" ?  <Image src="/image/gpay.png"/> :(
                                                    row.paymenttype === "ApplePay" ? <Image src="/image/apay.png"/> : (row.paymenttype)


                                                )}
                                            </Typography>

                                    </Grid>
                                </Grid>


                    ))



            ): null}



        </>

    );
}



export   function ConvertDate ({date})
{
    const t = date.split(/[- :]/);
    const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    console.log(d)
    return (
        <div> {d} </div>
    );
}