import {Box, CircularProgress, LinearProgress} from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

export default function ProgressLoader()
{

    return (
    <Box display={"flex"} justifyContent="center" alignItems="center" maxHeight={"100%"} minHeight={"50vh"} >
        <div>
            <CircularProgress/>
            <p/>
            <Typography>Loading...</Typography>
        </div>
    </Box>
    )
}