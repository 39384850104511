import {Component} from "react";
import axios from 'axios';



const API = 'https://app.al-ayn.ca/api2/index.php?';
const config = { 'Content-Type': 'application/json'}

 axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
 axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


class ApiRouter extends Component {

    static getData(method, callback, errorcallback) {
        axios.get(API + method )
            .then(res => {
                const json = JSON.parse(JSON.stringify(res.data));
                console.log(json)
                if (callback != null) {
                    callback(json);
                }
            })
            .catch(err => {
                if (errorcallback != null) {
                    errorcallback(err);
                }
            })
    }
    static fetchDataPost(method,params, callback, errorcallback) {

        axios.post(API + method , params)
            .then(res => {
                console.log(res,"FetchData")
                const json = JSON.parse(JSON.stringify(res.data));
                console.log(json,"FetchData")
                if (callback != null) {
                    callback(json);
                }
            })
            .catch(err => {
                if (errorcallback != null) {
                    errorcallback(err);
                }
            })
    }
}


export default ApiRouter;