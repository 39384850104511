import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter, MemoryRouter, Route, Routes,
} from "react-router-dom";
import Login from "./Login";
import { ReferenceDataContextProvider } from "./components/utils/ReferenceDataContext";
import Campaign from "./components/Campaign";
import MyDonations from "./components/MyDonations";
import Sadaqa from "./components/Sadaqa";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import RegisterMySadqaBox from "./components/RegisterMySadqaBox";
ReactDOM.render(
    <MemoryRouter>

        <App />

    </MemoryRouter>, document.getElementById( 'root' ) );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
